import React, { memo, useState } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { patchRequest } from '../../../utils/axiosRequests';
import { toast } from 'react-toastify';
import { loginSuccess } from '../../../store/slices/authSlice';

const BarcodeQrCode = () => {
  const userInfo = useSelector((state) => state.authReducer.data);
  const [codeType, setCodeType] = useState(userInfo?.codeType ?? '');
  const [signaturePosition, setSignaturePosition] = useState(
    userInfo?.signaturePosition ?? false,
  );
  const dispatch = useDispatch();

  const handleSignature = () => {
    patchRequest(`doctors/update-code-type/${userInfo.id}`, { codeType })
      .then((data) => {
        dispatch(
          loginSuccess({
            ...userInfo,
            codeType,
          }),
        );
        toast.success('Successfully uploaded', {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .catch((error) => {
        const errorMsg = error.message.split(',');
        toast.error(errorMsg, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  return (
    <div>
      <Row className="mt-3">
        <Col md={3}>
          <Form.Group controlId="code-type">
            {/* <Form.Check
              type="radio"
              id="barcode"
              name="codeType"
              label="Barcode"
              checked={codeType === 'barcode'}
              onChange={() => setCodeType('barcode')}
            /> */}
            <Form.Check
              type="radio"
              id="qrcode"
              name="codeType"
              label="QR Code"
              checked={codeType === 'qrcode'}
              onChange={() => setCodeType('qrcode')}
            />
            <Form.Check
              type="radio"
              id="none"
              name="codeType"
              label="None"
              checked={codeType === ''}
              onChange={() => setCodeType('')}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-2 mt-2">
        <Col md={1}></Col>
        <Col>
          <Button size="sm" onClick={() => handleSignature()}>
            Update
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default memo(BarcodeQrCode);
